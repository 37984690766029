import React from 'react';
import { useAuth } from '../AuthContext';

function Login() {
  const { signInWithGoogle } = useAuth();

  return (
    <div>
      <h1>Login</h1>
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    </div>
  );
}

export default Login;
