import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { auth, db } from './firebase';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import './css/Home.css';
import AppMenu from './components/AppMenu';

function Home() {
  const { user } = useAuth();
  const [stats, setStats] = useState({ users: 0, posts: 0, comments: 0 });
  const [isAuthorized, setIsAuthorized] = useState(false);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const handleSignOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    if (user) {
      checkAuthorization();
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  const checkAuthorization = async () => {
    if (!user) return;

    try {
      const userQuery = query(
        collection(db, 'authorizedUsers'),
        where('email', '==', user.email)
      );
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const now = new Date();
        const expirationDate = userData.expiresAt.toDate();

        if (now < expirationDate) {
          setIsAuthorized(true);
          fetchStats();
        } else {
          setIsAuthorized(false);
        }
      } else {
        setIsAuthorized(false);
      }
    } catch (error) {
      console.error("Error checking authorization:", error);
      setIsAuthorized(false);
    }
  };

  const fetchStats = async () => {
    try {
      const statsCollection = collection(db, 'stats');
      const statsSnapshot = await getDocs(statsCollection);
      const statsData = statsSnapshot.docs[0]?.data() || { users: 0, posts: 0, comments: 0 };
      setStats(statsData);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const incrementStat = async (statName) => {
    try {
      const newStats = { ...stats, [statName]: stats[statName] + 1 };
      const statsCollection = collection(db, 'stats');
      await addDoc(statsCollection, newStats);
      setStats(newStats);
    } catch (error) {
      console.error("Error updating stats:", error);
    }
  };

  return (
    <div className="container">
      <header className="header">
        <h1 className="title">Shing Enterprise</h1>
        <AppMenu />
      </header>
      <main className="main">
        {user ? (
          isAuthorized ? (
            <div className="dashboard">
              <h2>Admin Dashboard</h2>
              <p>Welcome, {user.displayName}</p>
              <div className="dashboardContent">
                <div className="dashboardItem">
                  <h3>Users</h3>
                  <p>Total: {stats.users}</p>
                  <button onClick={() => incrementStat('users')}>Add User</button>
                </div>
                <div className="dashboardItem">
                  <h3>Posts</h3>
                  <p>Total: {stats.posts}</p>
                  <button onClick={() => incrementStat('posts')}>Add Post</button>
                </div>
                <div className="dashboardItem">
                  <h3>Comments</h3>
                  <p>Total: {stats.comments}</p>
                  <button onClick={() => incrementStat('comments')}>Add Comment</button>
                </div>
              </div>
              <button onClick={handleSignOut} className="button">Sign Out</button>
            </div>
          ) : (
            <div className="userInfo">
              <p>Welcome, {user.displayName}</p>
              <p>You are not authorized to view the admin dashboard.</p>
              <button onClick={handleSignOut} className="button">Sign Out</button>
            </div>
          )
        ) : (
          <div className="signInBox">
            <p>Pls sign in if you have access</p>
            <button onClick={signInWithGoogle} className="button">
              <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="googleLogo" />
              Sign In with Google
            </button>
          </div>
        )}
      </main>
    </div>
  );
}

export default Home;
