import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJVntmXWV0SfLwztEFRzcxuxy4UdEbadk",
  authDomain: "iohnnj.firebaseapp.com",
  projectId: "iohnnj",
  storageBucket: "iohnnj.appspot.com",
  messagingSenderId: "1071912162280",
  appId: "1:1071912162280:web:ea900e8d02e635dd7a4a5d",
  measurementId: "G-Y1KF60BSLT"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
